<template>
    <div class="d-flex">
        <section class="pe-4">
            <h4 class="mb-2">Log</h4>

            <div class="auto-layout">
                <div class="row">
                    <label>Date</label>
                    <p>{{logitem.formatted_date}}</p>
                </div>
                <div class="row">
                    <label>URI</label>
                    <p>{{logitem.uri}}</p>
                </div>
                <div class="row">
                    <label>Module</label>
                    <p>{{logitem.controller}}</p>
                </div>
                <div class="row">
                    <label>Action</label>
                    <p>{{logitem.method}}</p>
                </div>
                <div class="row">
                    <label>User</label>
                    <p>{{logitem.user.first_name + ' ' + logitem.user.last_name}}</p>
                </div>
                <div class="row">
                    <label>User agent</label>
                    <p>{{logitem.user_agent}}</p>
                </div>
                <div class="row">
                    <label>IP</label>
                    <p>{{logitem.ip}}</p>
                </div>
            </div>

            <br>
            <h5>Gebeurtenissen</h5>
            <div v-for="event in logitem.logs" :key="event.id" style="border-bottom: 1px solid #dee2e6;" class="mb-2">

                <div class="auto-layout pt-2">
                    <div class="row">
                        <label>Model</label>
                        <p>{{event.class}}</p>
                    </div>
                    <div class="row">
                        <label>Function</label>
                        <p>{{event.function}}</p>
                    </div>
                </div>

                <template v-if="(event.diff_from && event.diff_from != '[]') || (event.diff_to && event.diff_to != '[]')">
                    <div class="row mb-2">
                        <div class="col-sm-6 col-form-label-sm">
                            Changes from <br>
                            <pre>{{event.diff_from}}</pre>
                        </div>
                        <div class="col-sm-6 col-form-label-sm">
                            Changes to <br>
                            <pre>{{event.diff_to}}</pre>
                        </div>
                    </div>
                </template>

                <div class="row mb-2" v-if="(event.before_state && event.before_state != '[]') || (event.after_state && event.after_state != '[]')">
                    <div class="col-sm-6 col-form-label-sm">
                        Before <br>
                        <pre>{{event.before_state}}</pre>
                    </div>
                    <div class="col-sm-6 col-form-label-sm">
                        After <br>
                        <pre>{{event.after_state}}</pre>
                    </div>
                </div>
                <pre v-else class="w-100">{{event.additional_information.replaceAll(',', ",\n")}}</pre>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'LogDetail',
        data() {
            return {
                logitem: {
                    request_body: "",
                    user: {}
                }
            }
        },
        methods: {
            validate(){
                return true;
            },
            getData() {
                return this.logitem;
            },
            reset(){
                this.logitem = {
                    request_body: "",
                    user: {}
                };
            },
            setData(data){
                this.logitem = data;
                this.logitem.formatted_date = this.formatDate(this.logitem.created_at, 'dateTime');
                if(!this.logitem.user){
                    this.logitem.user = {first_name: "Gebruiker", last_name: "Onbekend"};
                }
            }
        }
    }

</script>
<style scoped>
    pre:not(.w-100) { max-width: 350px }
    pre.w-100 { max-width: 700px }
</style>